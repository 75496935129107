// bulma breakpoints
// same as default
$gap: 32px
// adds ipad-mini, ipad-air and ipad (9.7") to tablet
$tablet: 768px
// moves ipad pro (12.9") to tablet
$desktop: 972px + (2 * $gap)

// bulma navbar
$navbar-item-active-background-color: black
$navbar-height: 4rem

// bulma modal content width
$modal-content-width: 80%

// is-success bulma color style
$success: #00B55C

// bulma menu styles
$menu-label-color: hsl(0, 0%, 0%)
$menu-label-font-size: .90em
$menu-item-active-background-color: #5BB588

@import "../../node_modules/bulma/bulma.sass"

#catalogDisplay
  position: relative
  overflow: auto

.filterCategoryCollapse
  float: right !important

#mobileFilterHeaderSection
  padding: 1.5rem

#mobileFilterContent
  margin-top: 50%
  background-color: rgb(246,246,246)

#mobileFilterBackGround
  background-color: rgba(10, 10, 10, 0.2)

// desktop and above
@include desktop
  #mainContainerFilter
    box-shadow: 10px 0 4px -2px #888

  .collapsedFilter
    height: 100vh

  #mainContainerFilter
    min-height: 100vh

  #catalogImage
    cursor: zoom-in !important

  #catalogImage:after
    content: '\A'
    width: 100%
    height: 100%
    position: absolute
    top: 0
    left: 0
    opacity: 0
    transition: all 0.5s
    -webkit-transition: all 0.5s

  #catalogImage:after
    background: rgba(0,0,0,0.4)

  #catalogImage:hover:after
    opacity: 1

// tablet and above
@include tablet

  #catalogModalContent
    width: 50%

  #scrollTopBtn
    right: 100px

  #inspirationControls
    width: 50%
    margin: auto

// up to mobile
@include mobile

  #inspirationControls
    width: 80%
    margin: auto

  #scrollTopBtn
    right: 30px

  .btnColor
    font-size: 1rem !important

  .btnBloomTime
    font-size: .9rem !important

  .btnBulbAttribute
    font-size: .9rem !important

  .dropdown-content
    padding: 0px

  .dropdown-item
    padding: 0px

  .navbar-item
    padding: 0.4rem

  #mobileFilterContent
    margin-top: 80%

  #mobileFilterHeaderSection
    padding: .5rem

  #mobileFilterInputSection
    padding: .5rem

  #headerTag
    font-size: .75rem

// only mobile and tablet
@include touch
  body.has-navbar-fixed-top
    padding-top: 3rem

  .navbar, .navbar-brand
    min-height: 3rem

  #catalog
    padding-right: 5px
    padding-left: 5px

  #catalogDisplay
    -ms-overflow-style: none
    scrollbar-width: none

  #catalogDisplay::-webkit-scrollbar
    display: none

  #mainContainerFilter
    padding: 0px !important
