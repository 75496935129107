html {
  background-color: rgb(238,238,238) !important;
}
body {
  margin: 0;
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hide-scrollbar {
  /*FireFox*/
  scrollbar-width: none;
  /*IE10+*/
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
html::-webkit-scrollbar {
  /*Chrome, Safari, Edge*/
  display: none;
}

#scrollTopBtn {
  position: fixed;
  bottom: 20px;
  /* modal is 40 */
  z-index: 39; 
  font-size: 22px;
  border: none;
  outline: none;
  background-color: #f2607e;
  color: white;
  cursor: pointer;
  padding: 25px;
  border-radius: 4px;
}

#scrollTopBtn:hover {
  background-color: #555;
}

.btnColor.is-active{
  border-width: 3px;
}

.btnCollapse {
  position: absolute;
  border-radius: 50%;
  transform: translate(-50%, 200%);
}

.unavailable {
  position: absolute;
  background: rgba(179, 179, 179, 0.8);
  color: azure;
  width: 100%;
}

.inspirationTitle {
  position: absolute;
  background: rgba(0,0,0,0.3);
  color: white;
  width: 100%;
}

.inspirationTitleModal {
  position: absolute;
  color: white;
  width: 100%;
}

.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

/* Fading animation */
.fade {
  animation-name: fade;
  animation-duration: 2s;
}

@keyframes fade {
  from {opacity: .6}
  to {opacity: 1}
}

.mySlides {
  display: none;
}

.slideShowImg {
  height: 70vh;
}

.white {
  color: #fff !important;
}

/* styling for height slider thumbs */
.noUi-handle{
  border: 1px solid #000000;
  height: 27px !important;
  width: 27px !important;
  border-radius: 50%;
  background: #ffffff;
  cursor: pointer;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; /* Add cool effects to your sliders! */
}

#spring {
  color: black;
}

.activeNavLink {
 border-bottom: 2px solid white !important;
}

.navbar-burger {
  height: auto;
}

.testing {
  background-color: transparent;
}

.loadingPlaceHolderCard {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  -webkit-animation: pulse 3s infinite;
  -moz-animation: pulse 3s infinite;
  animation: pulse 3s infinite;
}

.loadingPlaceHolderImage {
  /* background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab); */
  background: linear-gradient(-45deg, hsl(0, 0%, 14%), hsl(0, 0%, 29%), hsl(0, 0%, 71%));
  background-size: 250% 250%;
  -webkit-animation: gradient 5s ease infinite;
  -moz-animation: gradient 5s ease infinite;
  animation: gradient 5s ease infinite;
}

@keyframes gradient {
  0% {
    background-position:0% 50%;
  }
  50% {
    background-position:100% 50%;
  }
  100% {
    background-position:0% 50%;
  }
} 

@-webkit-keyframes gradient {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
@-moz-keyframes gradient {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}

@keyframes pulse {
  0% {
    /* transform: scale(0.95); */
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }
  70% {
    /* transform: scale(1); */
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
  100% {
    /* transform: scale(0.95); */
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
} 